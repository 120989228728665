import { memo } from "react";
import Img from "../../../../atoms/image";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";
import ss from "./f6821d92409ef5d6c449d445771f0ae80e0fbfeab4a700adee2b8431c201528c_640x480.png";

interface Props {}

export const Article20230327_3: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        現実逃避が機械学習の勉強の動機とは言いつつも、やはり楽しい。
        <br />
        もっと機械学習について語りたい。
      </P>
      <P>ということで、今作っているものについて話していく。</P>
      <P>
        僕の作りたいものは、マリオカートの結果画面から順位やレート、ポイントを読み取り、瞬時に集計を行うようなソフトウェアである。
      </P>
      <Img src={ss} />
      <P>
        この画面からポイントを読み取ることが目標である。
        <br />
        このソフトウェアを作るためには、機械学習に限らず、様々な機能を実装する必要がある。
      </P>
      <ul>
        <li>自動スクリーンショット機能</li>
        <li>
          タスクトレイへのアイコンの追加機能。マリオカートのリザルトが描画されているときにアイコンの見た目が変わる
        </li>
        <li>撮影したスクリーンショットを640x480にリサイズする機能</li>
        <li>モデルに画像データを渡し、解析結果を取得する機能</li>
      </ul>
    </ArticleContent>
  );
});

export default Article20230327_3;
