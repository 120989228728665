import { Link, Typography } from "@mui/material";
import { memo } from "react";
import Blockquote from "../../../../atoms/blockquote";
import CodeBlock from "../../../../atoms/code-block";
import { GLink } from "../../../../atoms/global-link";
import { P } from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

const CODE_BLOCK = `
gl.activeTexture(gl.TEXTURE0);
gl.bindTexture(gl.TEXTURE_2D, fBuffer.t);
`;

interface Props {}

export const Article20191224: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        何かを勉強すると何かを書き残したくなる。今はWebGLを学んでいるのだけど、それについて書き残したくて仕方がない。
      </P>
      <P>
        なぜ書き残したくなるか。おそらく自分の考えを整理したいのだと思う。自分の考えを文章にすることで、間違いに気付くことができる。それらの間違いを正していくことで、どんどん自分の考えが確固たるものになっていく。それと、物事を学ぶ過程を認識したいというのもある。何を勉強していて、どういった問題に遭遇し、それをどのように解決したか、それを知りたい。その中でも一番知りたいのは問題の解決方法である。もしかすると、その解決方法は別の問題に遭遇したときに使えるかもしれない。たとえ使えなかったとしても、「自分はしっかりと問題を解決する能力を持っている」という自信が得られる。
      </P>
      <P>ということでWebGLについて書いていきたいのだけど、眠たすぎるので寝る。</P>
      <P>
        起きた。今は
        <GLink href="https://wgld.org/">wgld.org</GLink>
        のチュートリアルを進めている。その過程で、<code>gl.viewport()</code>
        の使い方をはっきりと理解した。これを使うことにより、canvasを複数作ることなく画面上に異なる世界のオブジェクトを配置できるかもしれない。
      </P>
      <P>
        詳しく書いていく。WebGLでは、描画するものの情報（座標や色など）をGPUのバッファに入れて、コンパイルされた頂点シェーダとフラグメントシェーダを持つプログラムを有効にし、そのあとで
        <code>gl.drawArrays()</code>
        などの関数を呼び出してオブジェクトを描画する。ということで、複数のオブジェクトを描画するときに、
      </P>
      <P>（ここで夕食。焼きそばを作りながら日記に書く内容を考える）</P>
      <P>
        基礎が身に付いている状態とは、問題の解決方法をある程度予測できる状態のことである。たとえば「canvas上にまったく異なる複数のオブジェクトを描画するとき、どのように実装するのが最適か。プログラムは複数必要か。canvasは複数必要か。バッファはどのように確保すればいいか」という問題があったとき、基礎が身に付いていれば「複数のオブジェクトが同じ世界に属するかどうかで変わる。同じ世界に属するとき、プログラムとcanvasはひとつだけ用意するのが自然。バッファは描画するオブジェクトの「種類の数」分だけ確保する（オブジェクトの数ではない）。異なる世界に属するとき、プログラムとcanvasは世界の数だけ用意するのが自然。バッファをオブジェクトの種類の数だけ確保する点は同じ」と予測できる。
      </P>
      <P>
        これはあっているかもしれないし、間違っているかもしれない。いずれにしても、自分で実装して真偽を確かめることができる。あっていれば基礎は身に付いているといえるし、間違っていても、予測と異なる理由を明確にすることで基礎力が身に付く。基礎があれば成長できるというわけだ。
      </P>
      <P>
        基礎が身に付いていない場合はどうなるか。そもそも基礎が身に付いていないので、プログラムやcanvas、バッファなどの用語がわからない。よって、具体的な問いに落とし込むことができない。もしcanvasという用語だけを知っているとすると、問いは「canvas上に複数のオブジェクトを描画する場合にどうすればいいか」になる。そしてプログラムやバッファなどの道具が使えないので、解決方法を予測できない。プログラミングに精通していれば「描画するオブジェクトの情報を用意する必要がある」までは予測できるかもしれない。しかし、それ以上自分で予測するのは難しい。
      </P>
      <P>
        予測できていない状態で、人の指示に従い実現できたとする。…いや、これは物凄く恵まれた環境での話だ。僕の場合、基礎が身に付いていない状態で質問すること自体に抵抗がある。このような人は、予測できない状態のまま問題を解決しようとすると、途中で諦めてしまうか、たとえ解決できたとしても腑に落ちない。
      </P>
      <P>
        結局何が言いたいかというと、「3DCGの基礎を身に付けたい」とは「シェーダやバッファなどの道具の使い方を知りたい」という意味である。道具の使い方を知っていれば問題の解決方法をある程度予測でき、道具の使い方を知らなければ予測できない。これはおそらくすべての問題に対して言える。
      </P>
      <P>
        「3DCGの基礎を身に付けたい」ではアドバイスが難しいけど、「シェーダの使い方を知りたい」であればアドバイスはしやすい。しかも、具体的なアドバイスを得られる可能性が高い。
      </P>
      <P>
        これから僕が質問するときは、「○○（道具）の使い方を学べるサイトや書籍はありますか」のようにしよう。「△△の基礎を身に付けたいのですが、おすすめのサイトや書籍はありますか」では抽象的な回答しか得られない可能性が高い。
      </P>
      <P>夕食後なので少し眠気がある。</P>
      <P>
        今の僕は、物体の影を床に投影する方法がわからない。そして、そのときにキーとなる用語が何であるかもわからない。今は16:28。まずはキーとなる用語が何かを調べる。
        <GLink href="https://wgld.org/">wgld.org</GLink>
        に書かれていた気がするのでそこから探す。見つかった。今は16:30。およそ2分でキーとなる用語を見つけることができた。用語は「シャドウマッピング」だった。次はこの道具の使い方を知るために、実際に使ってみる。
        <GLink href="https://wgld.org/">wgld.org</GLink>
        のコードを写経すれば良さそうだ。今は16:31。では開始。
      </P>
      <P>
        今は16:32。冒頭にわからない単語がたくさん出てきた。オフスクリーン、デプスバッファ、フレームバッファ、モデルの深度値。たくさんといっても、実際には4つだった。読み進めると、デプスバッファについての説明がされている。説明を読んでおおよそ理解できた。デプスバッファの用途は、カメラから見て2つのオブジェクトが重なっている場合に、手前のものを優先して表示するときに使われるバッファのことである。データ構造は単純で、0.0～1.0の要素を持つ配列である。配列の要素数は、おそらく頂点の数。どうやらフレームバッファも0.0～1.0の要素を持つようだ（深度バッファとフレームバッファは同じ？）。
      </P>
      <P>
        読み進めるだけでデプスバッファを理解できた。今は16:54。「デプスバッファの深度値」は<code>gl_FragCoord.z</code>
        である。非常にシンプル。
      </P>
      <P>
        今は17:05。少しDiscordに気が逸れた。そのあとで
        <GLink href="https://wgld.org/">wgld.org</GLink>
        のページを読んだけど、驚愕の予測が生まれた。もしかすると、一度に実行する頂点シェーダが2つ以上あるかもしれない。最初に実行するプログラムはフレームバッファの状態を更新し、最後に実行するプログラムはダイレクトにcanvasを更新する。もしかするとそういうことなのかも。そうであれば非常に面白い。
      </P>
      <P>今は17:09。上の予測が正しい根拠が得られた。</P>
      <Blockquote>
        <Typography>
          変数<code>prg</code>が最終的にスクリーンにレンダリングを行なうシェーダ。変数<code>dPrg</code>
          がフレームバッファに深度値を描き込むためのシェーダです。
          <br />
          <GLink href="https://wgld.org/d/webgl/w051.html">wgld.org | WebGL: シャドウマッピング |</GLink>
        </Typography>
      </Blockquote>
      <P>
        書き込み先をスクリーンからフレームバッファに切り替えるために何をすればいいか。おそらく、
        <code>canvas.getContext("webgl");</code>
        を別のものに書き換える。そうすることで、<code>gl.drawElements()</code>
        が「フレームバッファに書き込む処理」になる。変数名は
        <code>dGl</code>といったところか。確かめてみる。今は17:15。<code>dGl</code>
        でページ内検索してもヒットしなかった。どうやら予測は間違っていたようだ。原因を探る。
      </P>
      <P>
        キーとなる部分は<code>gl.bindFramebuffer(gl.FRAMEBUFFER, fBuffer.f);</code>のようだ。名前から察するに、これは
        <code>gl.drawElements()</code>
        の書き込み先をフレームバッファに切り替える処理っぽい。フレームバッファではなくスクリーンにしたい場合は、
        <code>fBuffer.f</code>
        の部分を<code>null</code>にする。
      </P>
      <P>
        いつの間にか眠気が飛んでいる。おそらく「描画先が必ずしもスクリーンとは限らない」という新たな事実を知ってしまったからだと思う。これは考えもしなかった。
      </P>
      <P>
        コードを読み進めることで、頭の中にはあったがここには書き残さなかった疑問が解決された。疑問というのは「フレームバッファをどのように次のプログラムに渡すか」である。キーとなるのは以下の部分。
      </P>
      <CodeBlock language="javascript">{CODE_BLOCK.trim()}</CodeBlock>
      <P>
        つまり、フレームバッファの情報はテクスチャとして得る。…微妙に想像がつかない。やっぱり、実際にコードを動かしながら確かめたほうがよさそうだ。
      </P>
      <P>ここでちょっと振り返ってみる。</P>
      <P>
        今は17:30。今からちょうど1時間前に、「床に影を落としたいけどどう実現すればいいかわからない」という疑問があった。そして2分ほどでキーとなる用語「シャドウマッピング」を見つけた。そのあと、ではシャドウマッピングをどのように実現すればいいかについて調べ、フレームバッファや深度値などのわからない単語が出てきた。それらの単語については、シャドウマッピングの記事を読み進めると運よく説明が載っていた。だから理解できた。
      </P>
      <P>
        これがこの1時間での出来事。だいぶイメージは掴めてきたけど、実際に使うイメージはまだ掴めない。概要も理解したことだし、そろそろハンズオンの時間に入る。しかしその前に、風呂に入りたくて仕方がないので入る。
      </P>
      <P>風呂から出た。</P>
      <P sx={{ mb: 1 }}>フレームバッファの説明は、以下の文章が一番わかりやすい。</P>
      <Blockquote>
        <Typography>
          フレームバッファとは、コンピュータのメモリの中で、一画面分の表示状態を丸ごと保存しておく領域。
          <br />
          <Link
            href="http://e-words.jp/w/%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%90%E3%83%83%E3%83%95%E3%82%A1.html"
            target="_blank"
          >
            フレームバッファとは - IT用語辞典 e-Words
          </Link>
        </Typography>
      </Blockquote>
      <P>
        シェーダの実行結果がフレームバッファに格納される。フレームバッファから情報を取り出すにはどうすればいいんだろう。テクスチャとして取得するしかないのかな。となると、フレームバッファの実行結果はcanvasでしか確認できない。あ、でも、それなら自分で作れそう。順序としては以下のような感じ。
      </P>
      <ol>
        <li>フレームバッファの作成</li>
        <li>
          <code>gl.useProgram()</code>でフレームバッファ向けのプログラムを有効化
        </li>
        <li>
          <code>gl.drawArrays()</code>で描画
        </li>
        <li>描画結果をテクスチャとして取得</li>
        <li>
          <code>gl.useProgram()</code>でスクリーン描画用プログラムの有効化
        </li>
        <li>テクスチャを描画</li>
      </ol>
    </ArticleContent>
  );
});

export default Article20191224;
