import { memo } from "react";
import ArticleContent from "../../../../molecules/article-content";
import P from "../../../../atoms/p";
import ss1 from "./ss1.png";
import Img from "../../../../atoms/image";

interface Props {}

export const Article20230402: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        今日は日曜日。趣味で作成しているアプリはNNの構築で詰まっている。
        <br />
        これさえ作れれば後はスムーズに行くと思う。
        <br />
        <br />
        学習が進まない問題に直面している。
        <br />
        まだきちんと調査できていないのでこれから調査する。
        <br />
        どう調査すればいいか？
        <br />
        まずはNNの構造を明確にし、次にどこで問題が発生していそうかを予想する。
        <br />
        ある程度の予想を立てた後に検証し、予想が正しいかどうかを明らかにする。
        <br />
        <br />
        構築しているNNについて説明することは意外と難しい。
        <br />
        けれど、説明しようとすることで、新たな発見があるかもしれない。
        <br />
        ということで説明していく。
        <br />
        <br />
        最初に 0-255 を 0-1
        に正規化する。このような正規化を行う明確な理由は特に無い。みんなが色をこのように正規化しているからである。といっても、NN
        への入力データもこのような正規化をしないといけないかと聞かれると微妙。NN
        ではない機械学習では正規化をする意味もあった気がする。
        <br />
        ここで一つ疑問が湧いた。入力が 0-255
        の範囲であるとき、畳み込み層経由後も大きな値になるか？おそらく大きな値になると思うけれど、まだ確認したことがなかった。ということで確認する。
        <br />
        ついでに、tf.int32 型の値を渡せるかどうかも確認する。
        <br />
        あ、それと tf.int32 な Input を / 255 できるかどうかも確認したい。
        <br />
        <br />
        確認したところ、0-255 の範囲では出力値も大きくなり、0-1 の範囲の入力だと出力値が小さくなることがわかった。
        <br />
        畳み込み層を10回繰り返した場合はどれくらいおおきくなるんだろう？
        <br />
        予想では、それほど大きくならないはず。Conv2D
        の重みの初期値は入力ニューロンの数と出力ニューロンの数から動的に作られ、そのためニューロンの数に依らず値の範囲がある程度抑えられるはず。試してみる。
        <br />
        <br />
        どうやらその通りで、畳み込み層を10層重ねても、平均が200くらいにしかならなかった。
        <br />
        <Img src={ss1} style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 512 }} />
        <br />
        重みの初期値は-0.2-0.2
      </P>
    </ArticleContent>
  );
});

export default Article20230402;
