import { memo } from "react";
import { GLink } from "../../../../atoms/global-link";
import { P } from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20211220: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>最近は自堕落な日々を過ごしている。</P>
      <P>
        今は在宅勤務をしていて、誰にも監視されない環境にいる。遊んだり寝たりしていても誰にも注意されない。規則正しい生活を送るためにはしっかりと自制する必要がある。しかし、僕には自制する力がないため、寝る時間や食事の時間が不規則になってしまっている。
      </P>
      <P>
        孤独な日々を過ごしすぎて、もう人とのコミュニケーションのとり方がわからなくなってしまった。そして、人と関わりたいという欲も徐々に失われてきている。僕はもう終わりなのだろうか。
      </P>
      <P>
        まだ諦めたくない。かといって、孤独から逃れるために恋人を作ったり結婚したりするのも少し違う気がする。考えすぎだろうか。
      </P>
      <P>もう32歳なので、今から勉学に励むことはできないだろう。</P>
      <P>
        そういえば、最近は物理の力学の勉強をしている。仕事で簡単な物理の知識が必要になったので勉強したのだけど、そのときに満足のいく物理演算を実装できなかったので、その仕事を終えたあとも物理の勉強を続けている。
      </P>
      <P>
        まずはじめに、
        <GLink href="https://www.youtube.com/watch?v=szhJik4HIXQ">ヨビノリたくみさんの力学の連続講義</GLink>
        を最後まで視聴した。すると、物体にかかる力さえしっかりと把握すれば、それを運動方程式に代入して解くことで物体の位置を求められることを知った。運動方程式を解くことは基本的には難しいが、物理演算を実装するだけであれば運動方程式を解く必要はない。
      </P>
      <P>
        一通り勉強したあとは紙吹雪を実装したのだけど、なかなか現実の動きになってくれない。
        これはおそらく、最も単純な空気抵抗の式 -kv を使っているのが原因だと思う。
        紙のような平べったい物質は、進行方向から見た物質の表面積が大きいほど空気抵抗が大きくなるのが正しい。この性質は、最も単純な空気抵抗では表すことができない。
      </P>
    </ArticleContent>
  );
});

export default Article20211220;
