import { memo } from "react";
import MyDivider from "../../../../atoms/divider";
import { P } from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20210216: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>Google App Engineが好き。</P>
      <MyDivider />
      <P>
        GAEを使えば、自分で認証機能を実装することなく信頼できる認証システムを無料で使うことができる。この認証システムをIdentity-Aware
        Proxy（IAP）と呼ぶ。
      </P>
      <P>
        クライアントとGAEとの間にIAPを挟むと、GAEのプログラム開発を「認証されているユーザーがアクセスしてくる」という前提のもとで行うことができる。IAPの認証に通るためには、まずユーザーがGoogleアカウントを所持している必要がある。その後、Google
        Cloud Platform の管理画面からユーザーのGoogleアカウントを承認しなければならない。具体的には、Googleアカウントに
        <code>IAP-secured Web App User</code>
        ロールを割り当てる必要がある。しかしながら、AllUsersに対してロールを割り当てると、Googleアカウントを所持していないユーザーであってもGAEにアクセスできるようになる。これは、ひとつのプロジェクトで複数のGAEを動かし、一部は認証ありで一部は認証なしにしたいときなどに使える。
      </P>
      <P>
        Googleアカウントは一個人ではなく、グループやサービスが所持することがある。このようなGoogleアカウントのIDは、見た目はメールアドレスの形式だがメールボックスは存在しない。そして、グループが所持するGoogleアカウントに個人の所持するGoogleアカウントを複数紐付けることができる。こうすることで、集団に対してのロールの割り当て回数が、グループの所持するGoogleアカウントへのロールの割り当て1回で済ませることができる。
      </P>
      <MyDivider />
      <P>
        リクエストの形式などが正しくない場合は、その旨をクライアントに伝える必要がある。それに対してサーバー側が原因のエラーはクライアントに知らせる必要はない。サーバーのログとして残すのが適切である。
      </P>
      <P>
        このような切り分けをサーバー側で実装する際にはHTTPステータスコードが便利である。400台のエラーはクライアント側に返し、500台のエラーの詳細はサーバーのログとして残してクライアント側にはHTTPステータスコードのみを返すようにする。
      </P>
      <P>
        画像をサーバーに保存する方法のベストプラクティスを考える。画像データがユーザーデータと紐付いている場合、どのタイミングで画像を保存するかについて悩む。たとえばユーザーデータとアバターを同時にサーバーに保存するとき、次の2つの選択肢がある。
      </P>
      <ul>
        <li>まずユーザーデータを追加し、あとからアバターを追加する</li>
        <li>ユーザーデータとアバターを同じリクエストにまとめて追加する</li>
      </ul>
      <P>最初に思いつくのは後者ですが、前者のほうがいくつかの点で優れている。</P>
      <P>
        まず、ユーザー登録時にアバターを追加する必要がないため、ユーザーにとって登録の敷居が下がる。次に、サーバー側の実装の中に「データベースを操作してから画像ファイルを保存する」といった処理が入るが、これは異なるリソースを操作しているため失敗したときに整合性が失われる可能性がある（たとえばデータベースにデータが存在するのに画像は存在しないなど）。元から前者で実装しておくことで、このようなケースを考える必要がなくなる。
      </P>
      <P>
        ここから、ひとつのトランザクションとして扱いづらい処理は、元から分断させておいたほうがシンプルになるということがわかる。異なるリソースの操作をひとつのトランザクションとして扱うことは極力避けたい。
      </P>
    </ArticleContent>
  );
});

export default Article20210216;
