import { memo } from "react";
import { Link } from "@mui/material";
import Blockquote from "../../../../atoms/blockquote";
import MyDivider from "../../../../atoms/divider";
import P from "../../../../atoms/p";
import Q from "../../../../atoms/q";
import R from "../../../../atoms/r";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20230325: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        ここ2週間ほどは機械学習をガッツリと勉強していた。
        <br />
        最近は目的もない日々を過ごしていたけれど、2週間前にようやく作りたいものができた。
        <br />
        そのプログラムの実装には機械学習の力を借りなければならないため勉強している。
      </P>
      <P>
        Google Colab 上で沢山のプログラムを動かした。
        <br />
        このあたりで振り返っていく。
        <br />
        最近 AI の力を借りっぱなしで頭を使っていないので、頭を使うという意味で、まずは記憶だけで振り返っていく。
        <br />
        思い出しきったと思ったタイミングで Google Colab のページを開く。
      </P>
      <MyDivider />
      <P variant="h5">CNN</P>
      <P>実現したい機能の一つに、画像から文字を読み取りたいというのがある。</P>
      <P>
        画像というのはスクリーンショットのことで、文字の大きさは画像サイズに比べて小さい。よって、リサイズにより小さくなった画像から文字を読み取るのは難しい。かといって、そのままだとDNNに渡すデータの量が膨大になる（1920x1080のときは約6MB）。だから、リサイズ後の画像サイズは大きすぎても小さすぎても良くない。
      </P>
      <P>
        僕は640x480にした。このサイズにした理由は、僕が余裕を持って文字を読み取れるサイズだったからだ。AIは人よりも画像認識力が高いため、もっと小さくしても良かったが、今の僕の実力では画像認識力の高いAIを作れないと判断し、比較的大きなサイズにした。
      </P>
      <Q solved>
        <P noMargin>サイズが異なる画像を受け取れるようにDNNを構築する方法があった気がする。どんな方法か。</P>
        <MyDivider />
        <P noMargin>
          Conv2Dのパラメータ数は特徴マップのチャンネルを除くサイズに依存しない。よって (None, None, 3)
          の入力を受け取れるが、Dense層のパラメータ数は入力サイズに依存するため None が含まれていてはならない。None
          をなくすようなレイヤーを追加する必要がある。
          <br />
          今回は GlobalAveragePooling2D を使うことで None をなくした。
        </P>
        <P>
          そういえば、ミニバッチ内での形状は一致していなければならない。
          <br />
          <Link href="https://gist.github.com/penguinshunya/6a419b39202daaed1191a72ce907c176" target="_blank">
            untitled48.ipynb
          </Link>
          <br />
          「Cannot batch tensors with different shapes in component 0.」と言われる。
        </P>
        <P noMargin sx={{ mt: 2.5 }}>
          .padded_batch()を使うことで、ミニバッチ内のサンプルの形状を合わせられる。
          <br />
          <Link href="https://gist.github.com/penguinshunya/5423e325dd9e7228cc579cf583861033" target="_blank">
            untitled48.ipynb
          </Link>
        </P>
      </Q>
      <P>
        ところで、画像サイズは640x480で、文字の大きさは約24x24。画像の大半は無関係な部分である。そのような画像から文字を直接読み取るのは難しい。
      </P>
      <Q solved>
        <P noMargin>
          これはまだ試していないので試す。「真っ黒な背景に、白い文字がランダムな場所に描画される640x480の画像がある。その文字を正しく読み取るDNNは構築可能か？」。画像生成はOpenCVで、DNNの構築はTensorFlowで行える。
        </P>
        <MyDivider />
        <ul>
          <li>
            <Link href="https://gist.github.com/penguinshunya/e19c00b803f79516cd0ec73959e1567a" target="_blank">
              untitled45.ipynb
            </Link>
          </li>
          <li>
            <Link href="https://gist.github.com/penguinshunya/30cf7d67d27f24e935e1e11bf7345c7d" target="_blank">
              untitled45.ipynb
            </Link>
            ：縦に数字が5つ並んだパターン
          </li>
          <li>
            <Link href="https://gist.github.com/penguinshunya/927d69413629def28883c457dcbc2855" target="_blank">
              untitled45.ipynb
            </Link>
            ：5x5の中心の数字。学習進まず
          </li>
          <li>
            <Link href="https://gist.github.com/penguinshunya/7f7acd8bb44ab5286c28f48cdcf8bff6" target="_blank">
              untitled45.ipynb
            </Link>
            ：concatenateを行っても変わらず
          </li>
        </ul>
      </Q>
      <P>
        まずジェネレータを作成し、次にDatasetを作成し、そしてDNNを構築する。この手順が最もスムーズな気がする。あまりライブラリに詳しくなくてもジェネレータであれば作れるし、Datasetさえ作ってしまえばキャッシュやバッチ処理は簡単にできる。よって、DNNの構築に集中できる。
      </P>
      <P>
        NumPyがとても使いやすい。640x480x3のndarrayと640x480x1のndarrayの要素毎の積を取れたり、transposeやreshapeなどでテンソルの形を気軽に変えられる。
      </P>
      <Q solved>
        <P noMargin>
          TensorFlowの自動微分の機能は、tf.transposeやtf.reshapeを行っても正しく働くか？もし正しく働くのであれば、End-to-EndなDNNを構築しやすい。
        </P>
        <MyDivider />
        <P noMargin>
          tf.image.crop_and_resizeが正しく動作したので、tf.transposeやtf.reshapeも正しく動作すると判断して良さそう。
        </P>
      </Q>
      <P>
        tf.data.Dataset.from_generator()を使ってDatasetオブジェクトを作るとき、ジェネレータの返す値をTensorFlowに教えなければならない。今までは、next(iter())を一度だけ実行することで型を自動判別してほしいと思っていたけれど、Datasetの生成する値の形状は一部が不定でも良い（たとえば文章などは、パディングせずに返すことができる）ことを考えると、自動判別しない今の仕様のほうが嬉しいかもしれない（といいながら、「next(iter())を一度だけ実行して型を自動判別」がデフォルトの挙動でもいい気がする）。
      </P>
      <P>
        大量の画像データを利用するとき、今はGoogle Driveに保存し、Google
        Colabでマウントして直接参照している。これだと楽だけど、遅い気がする。速度を求めるなら、直接参照ではなくコピーしたものを参照するほうがいいかもしれない。Google
        Colabのストレージサイズは十分に大きいためこの方法のほうが良さそう。しかしこの方法だとGoogle
        Drive上のディレクトリを圧縮する必要が出てくると思う。…一応cpコマンドでも可能なのか。
      </P>
      <Q solved>
        <ul>
          <li>Google Driveのディレクトリを圧縮するのにかかる時間</li>
          <li>Google Driveのディレクトリをcpコマンドでコピーするのにかかる時間</li>
        </ul>
        <P noMargin sx={{ mt: 2.5 }}>
          この2つを測定する。
        </P>
        <MyDivider />
        <P noMargin>795MBのディレクトリの圧縮には8分29秒、コピーには55秒かかった。コピーが思った以上に速かった。</P>
      </Q>
      <P>
        ReLUを使うと学習が止まるようなDNNが、SELUに変えることで常時学習が進むようになり、DNNの損失も順調に減り続けた、という経験がある気がするけれど、きちんと記録として残していないので再現できない。きちんと再現できる形で残しておかなければならない。
      </P>
      <P>
        重要な文章を強調するようなコンポーネントを過去に作っていないか確認するために過去の記事をざっと眺めているのだけど、1年前もTensorFlowについて色々と勉強しているようだ。ほとんど記憶には残っていないけれど、このときに勉強したことが今回の開発に多少なりとも影響していると思う。
      </P>
      <R>
        <P noMargin>再現性がある形で記録に残す。</P>
        <MyDivider />
        <P noMargin>
          GitHub
          Gistにコードを保存して、そこへのリンクをここに貼ることで記録として残す、という方法が良さそう。Gistであれば結果を含めた状態を保存できる。ただ、Google
          Colabで無意識に保存するとGistが更新されるため、リンクを貼るときはコミット情報も含まれたURLを使う必要がある。このURLを得るのが多少面倒…。
        </P>
      </R>
      <P>
        標準のGPUを利用するのに1時間20円ほど、プレミアムは150円ほどかかる。計算するとかなり高い。「計算してみると意外と高い」というのが結構ありそう。たとえば食費は一日で2,000〜3,000円ほどかかっていると思っているけれど、計算するともっとかかってるかも。そういえば、実家からお米が届いた。美味しい米を食べると元気になれるかもしれない。最近はコンビニやスーパーの弁当ばかり食べていた。
      </P>
      <MyDivider />
      <P>
        AveratePooling2Dをうまく使えない。座標を求めるのは難しいとわかるけれど、分類問題についてはうまくいく気がする。けれどうまく行ったことがない気がする。一度だけうまく行った気がするけれど、これも記録に残っていない。記録に残すことは本当に重要だと痛感する。
      </P>
      <P>ということで、AveragePooling2Dの代わりにFlattenを使っている。Flattenであれば学習が進みやすい。</P>
      <MyDivider />
      <P>
        「softmaxはargmaxを微分可能にしたもの」というのが目から鱗の考え方だった。Wikipediaには次のように書かれている。
      </P>
      <Blockquote>
        <P noMargin>
          「ソフトマックス softmax」という名前は誤解を招く恐れがある。この関数は最大値関数の滑らかな近似ではなく、 Arg
          max関数（どのインデックスが最大値を持つかを表す関数）の滑らかな近似値である
          <br />
          <Link
            href="https://ja.wikipedia.org/wiki/%E3%82%BD%E3%83%95%E3%83%88%E3%83%9E%E3%83%83%E3%82%AF%E3%82%B9%E9%96%A2%E6%95%B0"
            target="_blank"
          >
            ソフトマックス関数 - Wikipedia
          </Link>
        </P>
      </Blockquote>
      <P>…Wikipediaには、直接は「softmaxは微分可能」とは書かれていなかった。どこに書かれていたかな…。</P>
      <MyDivider />
      <P>訓練データの数に対する検証データの数が1/16では少なすぎるので、最低でも1/5にする。</P>
    </ArticleContent>
  );
});

export default Article20230325;
