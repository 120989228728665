import { memo } from "react";
import ArticleContent from "../../../../molecules/article-content";
import P from "../../../../atoms/p";

interface Props {}

export const Article20240302: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        半年ほど虚無な時間を過ごした。
        <br />
        日記を書かないと虚無な時間を過ごしてしまう。
        <br />
        何か発信しなければならない。
      </P>
      <P>
        最近は競プロの過去問を解いている。
        <br />
        基本的にABCのD〜Fの問題を解いている。ARCの問題は解いていない。
        <br />
        本当にレートを上げたいのであればARCの問題も解けるようになるべきだけど、ARCの問題は頭を使う問題が多く、頭を使うことが苦手な僕には少しハードルが高い。
        <br />
        …趣味で競プロの問題を解くような人間なのに頭を使うことが苦手とはどういうことなのか…ｗ
        <br />
        ARCの問題が解けない理由は別にある…？
        <br />
      </P>
      <P>
        最近は本当に頭が働かない。
        <br />
        そういえば、今日の朝は久しぶりにランニングをした。
        <br />
        そして久しぶりに日記を書いている。
        <br />
        ランニングをしたことが、日記を書くという行為を促進した可能性がある。
        <br />
        やはり運動は習慣として取り入れたほうがいいのだろうか。
      </P>
      <P>そんなわけで、最近している競プロについて語りたいので語っていく。</P>
      <P>
        この2〜3週間で100問くらい解いている。
        <br />
        水色diffはだいたい解ける。青diffは半々、黄色diffはほんの少しだけ解けるといった感じ。
        <br />
        ただし時間は測っていないので、1日かけて解いている問題もいくつかある。
      </P>
      <P>
        日記を書きたいと思った一番の動機は、競プロで上達するために何を意識すればいいかについて、自分なりの方向性を明確にしておきたかったから。
        <br />
        最近は、うまい実装が思いつかなかったときに解説放送の実装を見て、特に自分で実装することなく終えるといったことがよくある。
        <br />
        自分で実装してみないとわからないことは沢山あると仮定すると、これは物凄く勿体ない解き方である。
      </P>
      <P>
        別解を見たときに理解しようとしないのも良くない気がする。
        <br />
        自分の考えというものを必要以上に大事にしているというか…。
        <br />
        このあたりは深く考えられていない。
        <br />
        あと、誤読も多い。誤読はD問題レベルでもたまにやってしまう。
        <br />
        そして問題の難易度が上がるほど誤読の頻度も高くなる。
      </P>
      <P>誤読を減らすためには何をすればいいのだろう。</P>
    </ArticleContent>
  );
});

export default Article20240302;
