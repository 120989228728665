import { memo } from "react";
import { GLink } from "../../../../atoms/global-link";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20220129: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>昨日はららぽーととIKEAに行った。</P>
      <P>
        最初は高尾山に行こうと思っていたのだけど、あまりにも遠かったので、とりあえず反対方向に行こうと思い、辿り着いたのが船橋だった。船橋駅で降りずに西船橋駅で降り、そこから徒歩で行ったのだけど、思った以上に遠くて足が疲れてしまった。その途中で大きな橋を渡った。どうやってこんな大きな橋を作ったんだろう。渡るだけで10分くらいかかった。下を見ると物凄く高くて怖かった。橋の近くに大きな工場があり、その建物の建て方も気になった。
      </P>
      <P>
        西船橋駅に着くまでに、船橋周辺の観光スポットをマップアプリで探した。するとIKEAを見つけたので降りたんだけど、歩いている途中にららぽーとがあることを知り、更にららぽーと内にポケモンセンターがあることを知った。これは行っておきたいと思ったので行った。
      </P>
      <P>
        ららぽーとは商業施設であり、ポケモンセンター以外にも様々な店があったが、ポケモンセンターだけ店内の雰囲気がまったく違った。店内にはぬいぐるみが沢山あり、とりあえず触ってみたのだけど、生地の触り心地が良かった。「オーバンド」という見慣れないものが売っていて、これはなんだろうと思いネットで検索すると輪ゴムだった。単純な円形の輪ゴムだけでなく、ピカチュウのしっぽの形の輪ゴムも入っているみたいだった。
      </P>
      <P>ポケモンセンターの後はIKEAに向かった。ららぽーとからIKEAまではすぐだった。</P>
      <P>
        IKEAは物凄く広かった。店に入ってエスカレーターで2階に行くと、レストランがあった。ここではパソコンで作業をしている人もいた。ここで仕事をするのも良さそうだと思った。店内を歩いていると、テーブルの天板が1,500円くらいで売っていた。足を合わせても3,500円ほどであり、物凄く安いと思った。買おうか悩んだけれど、IKEAで買うと入会させられそうなので買わなかった。
      </P>
      <P>
        IKEAには、家具の他にぬいぐるみや食器も売っていた。ハートのぬいぐるみはなんとなく特別感があった。サメのぬいぐるみもあり、とても可愛かった。
      </P>
      <P>
        販売エリアを抜けると、巨大な倉庫のような場所に行き着いた。とても高く、上の方にも大きなダンボールがあり、どうやって下ろすんだろう？などと考えた。今調べてみると
        <GLink href="https://www.youtube.com/watch?v=YpUN6TabB_E">こちらの動画</GLink>
        を見つけた。フォークリフトの前部分は縦に長く伸びるらしい。知らなかった。
      </P>
      <P>何も買わない人専用の出口が用意されていてそこを通った。少し申し訳なく思った。</P>
      <P>IKEAの後は帰宅した。</P>
    </ArticleContent>
  );
});

export default Article20220129;
