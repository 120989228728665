import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";

type Props = BoxProps;

export const R: React.VFC<Props> = memo(({ sx, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      {...props}
      sx={{
        bgcolor: "hsl(200, 0%, 32%)",
        borderBottom: "1px solid hsl(200, 0%, 30%)",
        borderTop: "1px solid hsl(200, 0%, 30%)",
        color: "white",
        mx: isMobile ? -2 : -3,
        my: 2.5,
        p: 2,
        px: isMobile ? 2 : 3,
        ...sx,
      }}
    />
  );
});

export default R;
