import { memo } from "react";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20210521: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        急に過去を振り返りたくなったので、文章を書きながら振り返ろうと思う。
        <br />
        プログラミングを勉強し始めた2012年8月から振り返る。
      </P>
      <P>
        2012年8月に、プログラミングを勉強できるスクールに入った。
        <br />
        そこには、プログラミングが大好きな先生がいた。
        <br />
        昔は漁師だったようで、魚の群れを検知するシステムを開発して成功したと聞いたことがある。
        <br />
        100個を超えるゲームを作っていて、スクールに入ったときに最初にやらされたのは、そのゲームのコードをひたすら写経することだった。
        <br />
        ゲームはすべて Visual Basic で作られていた。
        <br />
        言われたとおり、最初の数ヶ月間はずっと写経していた。
        <br />
        そのときに何を考えていたのかはよく覚えていない。
        <br />
        ひたすら写経するだけで、自分で改造したりといったことは全くしなかった。
        <br />
        普通は写経してゲームを動かした後に、何か改造したくなるもの、と先生に言われたことがある。
        <br />
        そうやって改造することでプログラミングスキルが上がることを先生は期待していたんだと思う。
        <br />
        しかし、僕にはどのように改造すればいいのかがわからなかった。
        <br />
        写経しているうちに徐々に書いていることがわかるようになってきたが、それでも改造の仕方がわからなかった。
        <br />
        おそらく、発想力や遊び心が足りないんだと思う。
        <br />
        そしてそれは今でも変わっていない。
      </P>
      <P>
        ある程度 Visual Basic を勉強した後は、C# と HTML/JavaScript を勉強した。
        <br />
        HTML/JavaScript を勉強する理由は、そのスクールの最終課題が「インターネット上の Web
        サイトから目的の情報を収集してわかりやすく表示する」だったからである。
        <br />
        先輩の作成したプログラムのプレゼンテーションを見る機会があったが、「株の動きを表示する」みたいな無難なプログラムだったので、正直詰まらなかった。
        <br />
        そのプログラムは、重い処理をしている間も画面に変化はなく、処理中かどうかが目で見てわからなかった。
        <br />
        プレゼンテーション終了後に感想を言う機会があり、そこで「画面が固まったかと思った」と発言した。
        <br />
        すると、少し不機嫌気味に「これでも早くなったほう」という回答を貰った。
        <br />
        そのやり取りを先生は楽しんでいるようだった。
      </P>
      <P>
        僕の発言は言葉足らずだった。
        <br />
        「比較的重い処理を行う際は、画面に何かアニメーションを表示するべきではないか」と発言するのが正解だと思う。
        <br />
        しかし、「画面が固まったかと思った」だけでは、相手はどうすればいいかわからないだろう。
        <br />
        中途半端なところで言葉を切らすのではなく、きちんと最後まで伝えるべきだった。
      </P>
      <P>
        ローディングについてもう少し語りたいので語っていく。
        <br />
        ローディング処理は、思っているよりも書くのが難しい。
      </P>
      <P>
        まず、ローディングアニメーションをどこに表示するのかを考えなければならない。
        <br />
        ボタンが押されたときはそのボタンに表示すればいい、としても良いが、そうなると、画面に複数のボタンがある場合はどうすればいいか。たとえば
        CRUD
        画面の「更新ボタン」と「削除ボタン」が横に並んでいるとき、更新ボタンが押されたときに削除ボタンにもローディングアニメーションを適用するかどうか。
        <br />
        その後、そもそも2つのボタンが横に並んでいるという設計自体が間違っているのではないかなどと考え始める。
        <br />
      </P>
      <P>
        こういうことを考えると、「ローディングについては最初に考えたほうがいいのではないか」と思い始める。
        <br />
        最初から考えておけば、後から UI を変更する手間がなくなるからである。
        <br />
        しかし、まだ経験が浅いプログラマが、果たして設計時にローディングのことを考えられるか。
        <br />
        おそらく最初は「こういう機能を実装したい」というのがあるだけで、その機能の一部にどれだけの処理時間がかかるか、そのときにどういったローディングを表示するかなどは考えない。
      </P>
      <P>
        言語の問題もあると思う。
        <br />
        JavaScript の場合は非同期処理が簡単に書けるのに対し、C# の場合は少々複雑な処理を書く必要がありそうだ。
        <br />
        当時は async/await
        という構文が存在しなかったため、何か重い処理をしながら画面にローディングを表示するためには、まず「スレッド」を理解しなければならない。
        <br />
        更にローディング時に進捗状況も表示したいとき、スレッド間でのデータの共有方法についても考える必要がある。
      </P>
      <P>「ただローディングを表示するだけ」であっても、色々と考える必要があることがわかった。</P>
      <P>今日のところは一旦このくらいで終わっておく。</P>
    </ArticleContent>
  );
});

export default Article20210521;
