import { memo } from "react";
import Img from "../../../../atoms/image";
import ArticleContent from "../../../../molecules/article-content";
import ss1 from "./ss1.png";
import ss2 from "./ss2.png";
import ss3 from "./ss3.png";
import ss4 from "./ss4.png";
import ss5 from "./ss5.png";
import ss6 from "./ss6.png";
import ss7 from "./ss7.png";
import ss8 from "./ss8.png";
import ss9 from "./ss9.png";
import ss10 from "./ss10.png";
import P from "../../../../atoms/p";
import MyDivider from "../../../../atoms/divider";

interface Props {}

export const Article20230401: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>先月の機械学習周りの勉強についてまとめる。</P>
      <MyDivider />
      <P variant="h6">3/6</P>
      <P>
        機械学習の勉強を再開。Discordにメモが残っている。
        <br />
        後で読み返すことを考慮していなかったので、所々情報が抜け落ちている。たとえば、最適化アルゴリズムとしてRMSPropsよりもAdamを選択したほうが学習が安定すると書かれているが、どういった学習をしたかについては書かれていない。畳み込みを行っているので画像関係のNNを構築しているのはわかるけれど…。
        <br />
        <Img src={ss9} style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 512 }} />
        <br />
        2層目以降の Conv2D で input_shape を設定してしまっているけれど、これでもきちんと動作するのだろうか？
        <br />
        そう思って次のコードを実行したところ、エラーは発生しなかった。
        <br />
        <Img src={ss10} style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 512 }} />
        <br />
        <br />
        Discord の方のコードで気付いたことがある。これは input_shape が (480, 640, 3)
        になっているため、おそらくこの頃から、マリオカート8DXの順位を読み取るNNを構築することを考えていたと思う。そうすると、マリオカート8DX関係のソフトウェアの開発に1ヶ月近くかかっていることになる。もっと短いと思っていた。時が流れるのが速い。
        <br />
        <br />
        それにしても最近は太った。今は94kgくらいある。半年前まで88kgだったにも関わらず。一年前は85kgの時期もあった。これはやばいと思い、最近はたまに外をランニングしている。最初のランニングの後は気分が良かったが、2回目以降のランニングではいい気分になれていない。どうすれば走った後の爽快感を得られるのだろう。そういえば、1回目は一瞬だけ思いっきり走った。あれが走った後の気分の良さに繋がっているかもしれない。
        <br />
        <br />
        3/6の振り返りをするつもりだったのに、いつの間にか自分が太っている話になり、そしてどのようにすれば走った後の爽快感を得られるかについて考えている。本当に目の前のことに集中できない。何らかの脳の障害なのかもしれない。けれど助けてくれる人はいない。中学生や高校生の頃に色々とひどいことをしたので自業自得である。死ぬまで若い頃の罪を背負って生きていくのが僕には相応しい。
        <br />
        こういうことを言うと自分の幼さに嫌気が差す。ほとんどの人は、30代でこんな中二病的な発言はしない。けれど、その事実を受け入れなければならない。これまでは、自分の発言の稚拙さを直視したくなかったのでこういった発言をすることは避けていたが、それだといつまで経っても治らないことがわかった。まずはありのままの自分を表現し、それを読み返しつつ徐々に改善していこうと思う。
        <br />
        <br />
        とにかく外を思いっきり走ろうと思う。
        <br />
        <br />
        思いっきり走ってきた。まだ体調が戻ったかどうかはわからないけど、なんとなく戻る気がする。
        <br />
        <br />
        もっと文章を洗練させないといけない。そう思った理由は、あとから読み返してもわかり易い文章にしたいから。自分が読むときに、できるだけわかりやすく、そして楽しく読めるような文章にしたい。
        <br />
        頭に思い浮かんだ文章をただ書き下すだけでは読みやすい文章にはならない。文章を書くという行為自体が意味を持つ場合もあれば、後から読み返して意味を持つ場合もある。そもそも意味とは何だろう？
      </P>
      <MyDivider />
      <P variant="h6">3/14</P>
      <P>
        縦線か横線かを判定するNNの構築。Sequential を利用してモデルを構築している。
        <br />
        このようなタスクであれば、Conv2D(2, 3) → MaxPool2D → Dense(1) のような単純なモデルでも解ける。
        <br />
        学習後の Conv2D(2, 3) のフィルタは縦線を判別しているようだった。
        <br />
        重みが次のようになっている。
        <br />
        <Img src={ss3} style={{ paddingTop: 4, paddingBottom: 4 }} />
        <br />
        畳み込み層のチャンネル数を2から1に減らすと、一部の画像で正しく判別できないことがわかった。
        <br />
        一部の画像とは次のような画像である。
        <br />
        <Img src={ss1} style={{ paddingTop: 4, paddingBottom: 4 }} />
        <br />
        右端に縦線があるような画像を正しく判別できていない。重みは次のようになっている。
        <br />
        <Img src={ss2} style={{ paddingTop: 4, paddingBottom: 4 }} />
        <br />
        正しく判別できない理由は、畳み込み後の値はすべてが 0 になってしまうから。
        <br />
        そういえば、ある地点のセルの出力がどうなっているかを確認する方法はあるだろうか。
        <br />
        TensorFlow で構築した NN は、勾配を計算するために出力値を一時的に保持しているはず。
        <br />
        少し振り返りから逸れてしまうけれど調べる。
        <br />
        調べた結果、新たにモデルをすれば良いことがわかった。
        <br />
        <br />
        random seedを使い始めたのもこの日。
        <br />
        実験結果を再現したいと思い始めて使い始めた。
      </P>
      <ul>
        <li>
          3/31：callメソッド内で tf.argmax, //, % が使われているようなレイヤーを使用したとき、
          <code>ValueError: No gradients provided for any variable</code>
          というエラーが発生する。
          <br />
          同様のエラーは次のコードで発生する。
          <br />
          <Img src={ss4} style={{ paddingTop: 4, paddingBottom: 4 }} />
          <br />
          <Img src={ss5} style={{ paddingTop: 4, paddingBottom: 4 }} />
          <br />
          つまり、変数に対応する勾配が与えられていないため発生しているエラーということになる。確かに、勾配が与えられていないと変数を更新することはできない。これは、次の更新式からわかる。
          <br />
          <Img src={ss6} style={{ paddingTop: 4, paddingBottom: 4 }} width={192} />
          <br />
          TensorFlow では、勾配は自動微分を使うことで簡単に求められる。tf.GradientTape()
          でコンテキストを作成し、自立変数または中間変数を tape.watch() で監視対象にし、計算を行い、そして
          tape.gradient() で勾配を求める。
          <br />
          具体的には次のようなコードになる。
          <br />
          <Img src={ss7} style={{ paddingTop: 4, paddingBottom: 4 }} />
          <br />
          上記コードの dy_dx は 2 になるが、x * 2 を tf.argmax(x) に書き換えた次のコードは None になる。
          <br />
          <Img src={ss8} style={{ paddingTop: 4, paddingBottom: 4 }} />
          <br />
          同様に、// による演算も None になる。None になる理由は、tf.argmax() や //
          は微分不可能だからだと思う。「微分可能」とは何だろう？あと、ReLU の x=0 のときの微分した値は 0
          になるけれど、それはどのように定義されているんだろう？もし微分の仕方を自分で定義できるとしたら、「微分可能なargmax」なども定義可能になるかもしれない。
          <br />
          その前に、自動微分の仕組みを知りたい。
        </li>
      </ul>
    </ArticleContent>
  );
});

export default Article20230401;
