import { memo } from "react";
import CodeBlock from "../../../../atoms/code-block";
import MyDivider from "../../../../atoms/divider";
import { GLink as GlobalLink } from "../../../../atoms/global-link";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20230327: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        お腹が減った。今は深夜の4時。空いているお店がコンビニくらいしか無い。
        <br />
        コンビニで何かを買うのもいいのだけど、今日は11度と寒いので、あまり出かける気になれない。かといって空腹感も凄いので、このままだと文章を書くことに集中できない。
        <br />
        水を飲んで空腹感を紛らわせることにする。本当にそれで紛れるのかはわからないけど…。
      </P>
      <MyDivider />
      <P>
        ということで機械学習の話に戻る。そういえば先ほど、寝ながら恒等写像について考えていた。
        <br />
        CNNを重ねていくと恒等写像を実現するのが難しくなるので、残差接続により差分のみを学習するようにするというもの。
        <br />
        本当にCNNを重ねると恒等写像が難しくなるの？という疑問が湧いたので、早速確かめていく。
      </P>
      <P>
        どのような実験をすればいいだろう？
        <br />
        ノイズのような画像を作成する。色はRGBで表し、それぞれを0-255のランダムな数にする。画像サイズは32x32とする。
        <br />
        次にモデルを構築する。入力と出力は共に(32,32,3)の形状にする。
        <br />
        CNNのチャンネル数とカーネルサイズはどうするか？
        <br />
        とりあえず、チャンネル数は3、カーネルサイズは1としよう。そうすれば恒等写像を表現しやすそう。
        <br />
        最初はCNNを一つだけ挟み、恒等写像が実現できるかどうかを確認する。
        <br />
        実現できることが確認できたら、次は一気に8層などにして確かめる。
      </P>
      <P>
        GPUが必要かどうかの判断の仕方がわからない。
        <br />
        そういえば、Pythonのgeneratorで画像を生成するとき、プログラムはCPUとGPUのどちらで動いているんだろう？
        <br />
        当然CPUだと思うけど、tf.random系のもので作ればGPU上で動作する？
        <br />
        いや、OpenCVの処理を記述するときに結局CPUに移動しなければならないし、それにgeneratorは並行処理ができないから、GPUの恩恵を得られ無さそう。
        <br />
        ChatGPTに聞こう。
        <br />
        <br />
        その前に、自分で確かめられることは確かめていく。
        <br />
        おそらく、GPUを有効にした状態でtf.randomを実行すると、データはGPU上に作られる。
        <br />
        どこにデータが置かれているかは、Tensorオブジェクトの.deviceで確認できるはず。
        <br />
        そして.numpy()を呼び出すことでデータがGPUからCPUにコピーされる。
        <br />
        …CPUにコピーという表現は間違っているな…。
        <br />
        もしかすると、「GPUにデータを作成」という表現も誤っているのかも。
        <br />
        GPU側にメモリが存在するとして、その領域にデータを作成することを、果たして「GPUにデータを作成」と表現していいものか。
        <br />
        <br />
        ChatGPTに質問したところ、GPUはメモリも重要な役割を果たしているため、単にGPUと言った場合でもGPUメモリも含まれる。よって、「GPUにデータを作成」という表現は正しいことになる。
        <br />
        …話が逸れたので、先ほどの話に戻る。
      </P>
      <P>
        GPUを有効にした状態で次のコードを実行する。
        <CodeBlock>tf.random.uniform((3, 3, 2)).device</CodeBlock>
        すると、次のように出力される。
        <CodeBlock>/job:localhost/replica:0/task:0/device:GPU:0</CodeBlock>
        データがどこに配置されたかや、演算がどこで行われたかは、次のコードを最初に書くことで確認できる。
        <CodeBlock>tf.debugging.set_log_device_placement(True)</CodeBlock>
        たとえば次のコードを実行したとき、
        <CodeBlock>
          tf.debugging.set_log_device_placement(True)
          <br />
          a = tf.random.uniform((3, 3, 3))
          <br />
          b = tf.random.uniform((3, 3, 3))
          <br />c = a * b
        </CodeBlock>
        次のように出力される。
        <CodeBlock>
          Executing op _EagerConst in device /job:localhost/replica:0/task:0/device:GPU:0
          <br />
          Executing op RandomUniform in device /job:localhost/replica:0/task:0/device:GPU:0
          <br />
          Executing op _EagerConst in device /job:localhost/replica:0/task:0/device:GPU:0
          <br />
          Executing op RandomUniform in device /job:localhost/replica:0/task:0/device:GPU:0
          <br />
          Executing op Mul in device /job:localhost/replica:0/task:0/device:GPU:0
        </CodeBlock>
        すべての操作がGPU上で行われていることがわかる。
      </P>
      <MyDivider />
      <P>
        Google ColabでCuPyが最初から使える！
        <br />
        今のところ頻繁に使うことは無さそうだけど、いつか使う時が来るかもしれない。
      </P>
      <MyDivider />
      <P>
        恒等写像の実験を行っていく。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/d4c855f5422c483da895dabf6223b84d">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        CNNがひとつだけのモデル。ほぼ完璧な恒等写像を実現できた。次は5つに増やして確かめる。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/8b0341d3710587ac62228d639a62493f">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        5つであっても恒等写像を実現できる。次は32個にする。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/e604e9eae7ed1d785ab7254d0200c5a9">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        全く学習が進まなくなった。次は残差接続を行う。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/fd758c5610c7b25fb268b05462d0fa55">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        完全ではないが、人の目にはわからないレベルの恒等写像を実現できている。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/f838c9a46ba58d78251ad83abcf6cbeb">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        32層、残差接続無しで、活性化関数としてSELUを設定したもの。少しずつ学習が進んでいるが、恒等写像は難しそう。
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/f153a1f2ef8fc28773299ac9b5e274e4">
          Untitled51.ipynb
        </GlobalLink>
        <br />
        Batch Normalization
      </P>
    </ArticleContent>
  );
});

export default Article20230327;
