import { memo } from "react";
import MyDivider from "../../../../atoms/divider";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20211229: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>VTuberさんの企画が面白い。</P>
      <P>
        パシフィックリリム、ギルザレンⅢ世のマリカ大会での字幕、朝ココ、ワード人狼、声真似へたくそ王。それ以外にも、たとえば
        Among Us や マリオカート8 デラックス
        の大型コラボも面白く、一度見始めると止まらない。更に、面白い企画には切り抜き動画もあるため、それらを合わせて見ると一日をVTuberさんの動画に費やしてしまうことになる。
      </P>
      <P>どうすればこんなに面白い企画を考えられるんだろう。どうすればこんなに面白い発言ができるんだろう。</P>
      <P>
        その中でも特に凄いと思ったのは、ギルザレンⅢ世のマリカ大会での字幕である。マリカ大会の公式放送ではレースの参加者全員の画面が映し出されていて、各参加者の音声はオフになっている。各参加者は公式放送で目立つために様々な策を講じる。たとえば背景を動画にしてみたり、顔をドアップにしたりである。ここでギルザレンⅢ世は、発言を自動で文字起こしして画面に映すという策をとった。たったそれだけのことなのに、発言内容であったり文字起こしの微妙な間違いであったりがうまく合わさり、ものすごく面白いものになっている。
      </P>
      <P>
        更に、ギルザレンⅢ世自身は配信を年に数回しかしておらず、それがマリカ大会でのいじりのネタにされていた。もし僕がギルザレンⅢ世なら、全く触れられることはないだろう。どうすればこんなに弄ってもらうことができるのか。
      </P>
      <P>ギルザレンⅢ世のような人が本当に羨ましい。</P>
      <MyDivider />
      <P>今日はイラストを作る気力が湧かないのでお休み。</P>
    </ArticleContent>
  );
});

export default Article20211229;
