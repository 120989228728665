import { memo } from "react";
import { GLink } from "../../../../atoms/global-link";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20230328: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        ぼっち・ざ・ろっく！を最終回まで見た。
        <br />
        ここまで面白いアニメを見たのは久しぶりかも。
        <br />
        喜多さんが好き。
      </P>
      <P>
        <GLink href="https://gist.github.com/penguinshunya/56d57416f2799186c9dbae054cb07a5f">
          upsampling-and-resize-is-same-result.ipynb
        </GLink>
        <br />
        UpSampling2D と Resizing は同じ結果になる
      </P>
      <P>
        <GLink href="https://gist.github.com/penguinshunya/36f8ab297c22f8e478edad9a2383abb4">
          resize-image-is-float-type.ipynb
        </GLink>
        <br />
        Resizing を使うと float 型になる
      </P>
      <P>
        あるものの位置を正確に求めたい。
        <br />
        もし対象の物体が200pxを超えるとき、3x3の畳み込みを10回行ったところで全体の情報を得ることはできない。
        <br />
        たとえばマリオカートの順位表の場合、参加者が何人かという問いに答えるためには400px近くの情報を見通す必要があるが、3x3の畳み込みを10回行っても全体は見渡せず、問いに答えることができない。
        <br />
        もしかするとDense層で全体を見渡せるかもしれないけれど、どうなんだろう。
      </P>
      <P>
        そういえば、昨日はいい発想を得た。
        <br />
        Conv2Dのstrideに2以上の値を設定することで、Conv2Dを行った後のサイズが約半分以下になる。
        <br />
        こうすることで、MaxPool2Dのように大幅に情報を圧縮できる。
        <br />
        更にMaxPool2Dと違ってパラメータを持つ上にチャンネル数を増やせるため、ピクセル単位の情報を失わなさそう。
      </P>
      <P>
        ということで早速実験する。
        <br />
        今回はConv2DとMaxPool2D、Flatten、Denseのみを使用する。
        <br />
        640x480画像のランダムな位置に円を描画し、その中心座標を学習により求める。
        <br />
        モデルは2つ作成し、その結果を比較する。各モデルは次のように定義する。
      </P>
      <ul>
        <li>
          Conv2DとMaxPool2Dを5回繰り返す。カーネルサイズ3、チャンネル数16。
          <br />
          この構成にすることで、おそらく1024pxの範囲の情報を取得できる。…これも実験しよう
        </li>
      </ul>
      <P>
        直接実験と関係のないことを色々と試していた。
        <br />
        stridesを2にすることで特徴マップの大きさを1/2にできる。MaxPool2Dを使わなくていいかもしれない。
        <br />
        パラメータ数はstridesに依らないのか？これは簡単に確認できるので確かめる。
        <br />
        →パラメータ数はstridesに依らなかった。
      </P>
    </ArticleContent>
  );
});

export default Article20230328;
