import { Box, Link } from "@mui/material";
import { memo } from "react";
import CodeBlock from "../../../../atoms/code-block";
import MyDivider from "../../../../atoms/divider";
import P from "../../../../atoms/p";
import Q from "../../../../atoms/q";
import { GLink as GlobalLink } from "../../../../atoms/global-link";
import ArticleContent from "../../../../molecules/article-content";
import ss from "./ss.png";
import ss2 from "./ss2.png";
import Img from "../../../../atoms/image";

interface Props {}

export const Article20230326: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <Q solved>
        <P noMargin>
          <Link href="https://gist.github.com/penguinshunya/941246a5dbe03d23251e9cef0362a776" target="_blank">
            untitled46.ipynb
          </Link>
        </P>
        <P noMargin>crop_and_resize()を使った &amp; End-to-Endなモデルを作成（途中）</P>
        <P noMargin>
          <Link href="https://gist.github.com/penguinshunya/71d84ffc26750beba886994c47caf1b8" target="_blank">
            untitled46.ipynb
          </Link>
        </P>
        <P noMargin>
          とてもいい感じに学習が進んだもの。coord_modelを途中まで学習させ、loss_weightsを[0.9, 0.1]に設定した
        </P>
        <P noMargin>
          <Link href="https://gist.github.com/penguinshunya/abce26c578e7c7332169db97903d6604" target="_blank">
            untitled46.ipynb
          </Link>
        </P>
        <P noMargin>
          一度ランタイムを削除してEnd-to-Endのモデルのみ学習させてうまくいくかの実験結果。複数のモデルを組み合わせて作った巨大なモデルも、loss_weightsを正しく設定することで順調に学習が進むことがわかった。
        </P>
        <Box sx={{ my: 1 }}>
          <Img src={ss} />
        </Box>
      </Q>
      <Q>
        <P noMargin>
          loss_weightsは、合計が1.0になるようにしたほうがいいか？
          <br />
          たとえば[99,1]と設定すると学習が進みにくい
        </P>
      </Q>
      <MyDivider />
      <P>
        TensorFlowでモデルを構築するとき、一部を外部に切り出したいことがある。このとき、LayerとModelの2つの選択肢がある。どちらが適切か？
      </P>
      <P>
        独立して学習させたいかどうかだと思う。独立して学習させたいときはModelを、その必要がないときはLayerを選択する。
      </P>
      <MyDivider />
      <P>モデルを作成する手順は、今のところ次が一番いい（Jupyter Notebookの使用を前提とする）。</P>
      <ol>
        <li>generator関数の作成。このコードセルに他の関数も定義する</li>
        <li>上記セル内にoutput_signatureを定義。generator関数の下に書く</li>
        <li>新しくセルを作成し、Datasetとmap関数を定義し、ds.map().batch().take()を書く</li>
        <li>新しくセルを作成し、そこでモデルを組み立てる</li>
        <li>新しくセルを作成し、モデルのコンパイルと.fitを書く</li>
        <li>新しくセルを作成し、historyの可視化やテストデータの結果確認などを行う</li>
      </ol>
      <P>
        3と4がこの順番なのは、2の実装を行っているとき、2の動作確認をすぐに行いたいと思っているから。4から行うと、2の動作確認をするまでの期間が空いてしまい、バグに気づくまでに時間がかかる。
        <br />
        ただ、generator関数の動作確認はnext(iter(generator()))のほうが楽であり、2の実装途中にこういった細かな確認は行うため、動作確認が十分に行えているのであれば3と4は入れ替えても良い。
      </P>
      <P>
        場合によってはモデルの保存とロードを行うこともある。モデルの保存は5と6の間、ロードは3と4の間で行う。どちらも新しくセルを作成する。
      </P>
      <P>ということで、セルの個数は5〜8個くらいになる。importを記述するセルを含めると6〜9個。</P>
      <Q solved>
        <P noMargin>学習が安定しなくなったときに学習率を下げるようなスケジューラはあるか？</P>
        <MyDivider />
        <P noMargin>
          「学習が安定しなくなったとき」ではなかったが、検証損失が改善しないときに学習率を下げてくれるReduceLROnPlateauというスケジューラがあった。
          <br />
          次のコードは、検証損失が5エポック改善されなかったときに学習率を0.3倍するコードである。
        </P>
        <CodeBlock>
          reduce_lr = tf.keras.callbacks.ReduceLROnPlateau(monitor="val_loss", factor=0.3, patience=5)
          <br />
          model.fit(train_ds, callbacks=[reduce_lr])
        </CodeBlock>
        <P noMargin>これからは、EarlyStoppingと組み合わせて使う。</P>
      </Q>
      <P>
        PILを使うことはないと思っていたが、OpenCVではテキストを含む画像の生成がしづらいため、PILを試したい気持ちになっている。Google
        Fontsなどのフォントが使えるらしい。ということで試してみる。
      </P>
      <P>
        cv2.imshowをGoogle
        Colab上で実行するとエラーが出る。しかし、PILであれば変数を評価するだけで画像を表示してくれる。これはとても楽。
        <br />
        PILを試していても全然楽しくないので、一旦はOpenCVで色々なことをする。
      </P>
      <MyDivider />
      <P>
        振り返り再開。振り返りというよりも、やりたいことが色々とある。可変長な入力を受け取るモデルを構築できるようになったので、これを使って画像文字認識を実装したい。
      </P>
      <P>
        <GlobalLink href="https://gist.github.com/penguinshunya/31d34926815feddc886e566660c3b06d">
          various-size-images
        </GlobalLink>
        <br />
        32x32 - 64x64 の画像で学習し、72x72 - 96x96
        の画像を検証データとして使用したところ、正解率は70%ほどだった。ここで不意にDropoutを試したくなったので試してみる。
        <br />
        Dropoutを入れるとよくわからない結果になった。一旦放置。
        <br />
        <br />
        <GlobalLink href="https://gist.github.com/penguinshunya/deb64de51d629d475437b31894ef6cd2">
          various-size-images
        </GlobalLink>
        <br />
        学習データの画像サイズを 64x64 - 104x104
        に変更したところ、順調に学習が進んだ。やはり本番のデータの画像サイズを訓練データに含めたほうが良い。
      </P>
      <Box>
        <Img src={ss2} />
      </Box>
      <P>正解率は98%だった。</P>
    </ArticleContent>
  );
});

export default Article20230326;
