import { Typography, TypographyProps } from "@mui/material";
import { memo } from "react";

type Props = TypographyProps & {
  noMargin?: boolean;
};

export const P: React.VFC<Props> = memo(({ noMargin, sx, ...props }) => {
  return (
    <Typography
      {...props}
      sx={{
        my: noMargin ? 0 : 2.5,
        ...sx,
      }}
    />
  );
});

export default P;
