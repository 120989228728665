import { memo } from "react";
import P from "../../../../atoms/p";
import ArticleContent from "../../../../molecules/article-content";

interface Props {}

export const Article20230327_2: React.VFC<Props> = memo(() => {
  return (
    <ArticleContent>
      <P>
        ぼっちちゃんが可愛かった。
        <br />
        高校生はまだまだ将来があるけれど、33歳になってもネット含めて友達が一人しかいないような僕の将来はほとんどないんだろうな、と思い寂しくなった。
        <br />
        僕は扱いにくいんだろうな。
        <br />
        やりたくない仕事はしないし、嫌だと思ったときにすぐに縁を切ってしまうし。
        <br />
        <br />
        とこんな感じで、アニメの話から自分の話に持っていくのも嫌われる要因なんだろう。
        <br />
        嫌われてはいないか。
        <br />
        <br />
        何が言いたいかが自分でもよくわからないけれど、こういったことを話すのは本当に久しぶりな気がする。
        <br />
        ここ数ヶ月間は死んだような生活をしていた。
        <br />
        一昨日に数ヶ月ぶりに外を走ってからは、少しだけ体調が良くなった。自発的な行動も増えた気がする。
        <br />
        日記を更新したのも一年ぶりくらいだ。
        <br />
        <br />
        今書いている文章は、おそらく誰にも見てもらえないだろうけど、将来の自分のために残しておく。
        <br />
        将来の自分のために書くのだから、もう少し集中して書かないといけないな。
        <br />
        たまに音楽を流しながら文章を書いているけれど、そのときに書く文章は支離滅裂になることが多い。
        <br />
        まあ音楽を流していなくても最近は支離滅裂な文章になることが多いのだけど。
        <br />
        今はたぶん大丈夫なはず。
        <br />
        <br />
        1年前に好きな人がいた。
        <br />
        その子とは勉強会で知り合った。
        <br />
        最初は3人での勉強会だったが、勉強会の後に2人で公園を歩きながら1時間ほど話して仲良くなった。
        <br />
        次からの勉強会は2人で行うようになった。
        <br />
        4回目の勉強会のときに告白したが、仕事に集中したいという理由で振られてしまった。
        <br />
        その後も交友関係は続いたが、半年前ほどに僕が辛くなり、喧嘩をして連絡を取らなくなった。
        <br />
        そんな日々が続いたが、1ヶ月ほど前に連絡が来た。
        <br />
        久しぶりに話せて僕は喜んだが、久しぶりだったが故に距離感を見誤り、あらぬことを発言してしまった。
        <br />
        その発言を後悔しながら、何も言わずにLINEの友達登録を解除した。
        <br />
        <br />
        しんどい。
        <br />
        なぜすぐに縁を切ってしまうんだろう。
        <br />
        今も仕事を辞めたくなっている。
        <br />
        機械学習の勉強に集中したいという理由だけれど、そもそも仕事が充実していればこんなことは考えなかっただろう。
        <br />
        機械学習の勉強は現実逃避の一種である。
        <br />
        <br />
        僕の唯一の友達は、今は家庭の方で色々あり大変そうだ。
        <br />
        元気にしてるだろうか。
      </P>
    </ArticleContent>
  );
});

export default Article20230327_2;
